<template>
  <tr :class="{ 'is-busy': isDeleting }">
    <td @click="$emit('update', exercise)" class="align-middle" role="button">
      <div>{{ exercise.name }}</div>
      <div v-if="description" v-html="description" class="smaller text-muted"></div>
    </td>
    <td class="align-middle">
      <div v-if="exercise.videoUrl">
        <IconButton
          @click="showPreviewModal = true"
          :icon="getVideoIcon(exercise.videoUrl)"
          :iconSource="getVideoIconSource(exercise.videoUrl)"
          :text="'View'"
          class="btn btn-sm btn-primary"
        ></IconButton>
      </div>
      <span v-else-if="videoNotReady" class="small text-muted fst-italic">in progress...</span>
      <span v-else class="small text-muted">&mdash;</span>
    </td>
    <td class="align-middle text-end">
      <IconButton
        @click="showConfirmModal = true"
        v-if="!videoNotReady"
        :icon="'trash-alt'"
        :isLoading="isDeleting"
        :disabled="isDeleting"
        class="btn btn-sm btn-danger"
      ></IconButton>
    </td>

    <portal to="modal">
      <transition name="modal">
        <ConfirmModal
          @confirm="deleteExercise"
          @close="showConfirmModal = false"
          v-model="showConfirmModal"
          v-if="showConfirmModal"
        ></ConfirmModal>
      </transition>
    </portal>

    <portal to="modal">
      <transition name="modal">
        <ResourcePreviewModal
          @close="showPreviewModal = false"
          v-if="showPreviewModal"
          v-model="showPreviewModal"
          :url="exercise.videoUrl"
        ></ResourcePreviewModal>
      </transition>
    </portal>
  </tr>
</template>

<script>
export default {
  name: 'ExerciseListItem',
  components: {
    IconButton: () => import('@/components/button/IconButton'),
    ConfirmModal: () => import('@/components/modal/ConfirmModal'),
    ResourcePreviewModal: () => import('@/components/modal/ResourcePreviewModal'),
  },
  props: {
    exercise: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    description() {
      const { description } = this.exercise;
      if (description && description !== '') return this.$options.filters.nl2br(description);
      return null;
    },
    videoNotReady() {
      const { videoSource, videoUrl, status } = this.exercise;
      return videoSource === 'upload' && videoUrl === '' && status !== 'ready';
    },
  },
  methods: {
    async deleteExercise() {
      this.showConfirmModal = false;
      this.isDeleting = true;
      try {
        const itemId = this.exercise.exerciseLibraryId;
        await this.$store.dispatch('library/deleteItem', itemId);
      } catch (err) {
        this.$store.dispatch('addSystemError', err, { root: true });
      } finally {
        this.isDeleting = false;
      }
    },
    getVideoIcon(url) {
      if (url.indexOf('vimeo.com') > -1) return 'vimeo';
      if (url.indexOf('youtube.com') > -1) return 'youtube';
      if (url.indexOf('youtu.be') > -1) return 'youtube';
      return 'film';
    },
    getVideoIconSource(url) {
      if (url.indexOf('vimeo.com') > -1 || url.indexOf('youtube.com') > -1 || url.indexOf('youtu.be') > -1) {
        return 'fab';
      }
      return undefined;
    },
  },
  data() {
    return {
      isDeleting: false,
      showConfirmModal: false,
      showPreviewModal: false,
    };
  },
};
</script>
